import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import dayjs from "dayjs";
import $ from 'jquery';
import 'slick-carousel';
import { Fancybox } from '@fancyapps/ui';
import HubspotForm from 'react-hubspot-form'


import './App.css';

function App() {
  const [projects, setProjects] = useState([]);
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [bgImageUrl, setBgImageUrl] = useState('');
  const [menuLinks, setMenuLinks] = useState([]);
  const [aboutBio, setAboutBio] = useState('');
  const [portraitUrl, setPortraitUrl] = useState('');
  const [greeting, setGreeting] = useState('Hello!');
  const [aboutSkills, setAboutSkills] = useState('Yeah I got skills.');
  const [skillsLogos, setSkillsLogos] = useState([]);
  const [portfolioTitle, setPortfolioTitle] = useState('Portfolio');
  const [portfolioBlurb, setPortfolioBlurb] = useState('Behold! My stuff.');
  const [contactMe, setContactMe] = useState('');
  const [contactMeFormId, setContactMeFormId] = useState('');
  const [contactMePortalId, setContactMePortalId] = useState('');
  const [linkedInUrl, setLinkedInUrl] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/rac_projects`);
        const data = await response.json();
        const projectsWithImages = await Promise.all(data.map(async project => {
          const imageUrl = await fetchImageData(project.featured_media);
          const acfData = await fetchProjectAcfOptions(project.id);
          const technologies = await fetchTechnologies(project.rac_technologies);
          /*const acfResponse = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/rac_projects/${project.id}`);
          const acfData = await acfResponse.json();*/
          
          return { ...project, imageUrl, acf: acfData, rac_technologies: technologies};
        }));
        setProjects(projectsWithImages);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchImageUrls = async (imageIds, fieldName) => {
      try {
        if (imageIds.length) {
          const urls = await Promise.all(
            imageIds.map(async (imageId) => {
              const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/media/${imageId}`);
              const data = await response.json();
              return data.source_url;
            })
          );
          
          if ( fieldName == 'skill_images') {
            setSkillsLogos( urls );
          } else {
            return urls;
          }
        }
      } catch (error) {
        console.error('Error fetching image URLs:', error);
      }
    };


    const fetchAcfOptions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/pages/22`);
        const data = await response.json();
        const logoImageId = data.acf.logo;
        const portraitImageId = data.acf.portrait;
        const bgImageId = data.acf.background_image; 
        fetchImageData(logoImageId, 'logo');
        fetchImageData(portraitImageId, 'portrait');
        fetchImageData(bgImageId, 'background_image')
        setMenuLinks(data.acf.menu_links);

        let dirtyBio = data.acf.about;
        let cleanBio = DOMPurify.sanitize(dirtyBio);
        setAboutBio(cleanBio);
        setGreeting(data.acf.greeting);

        let dirtySkills = data.acf.about_skills;
        let cleanSkills = DOMPurify.sanitize(dirtySkills);
        setAboutSkills(cleanSkills);
        fetchImageUrls(data.acf.skill_images, 'skill_images');

        const portfolioTitle = data.acf.portfolio_title;
        setPortfolioTitle(portfolioTitle);
        const portfolioBlurb = data.acf.portfolio_blurb;
        setPortfolioBlurb(portfolioBlurb);

        const contactMe = data.acf.contact_content;
        setContactMe(contactMe);

        const contactMeFormId = data.acf.form_id;
        setContactMeFormId(contactMeFormId);

        const contactMePortalId = data.acf.portal_id;
        setContactMePortalId(contactMePortalId);

        const linkedInUrl = data.acf.linkedin_link;
        setLinkedInUrl(linkedInUrl);

      } catch (error) {
        console.error('Error fetching ACF options:', error);
      }
    };

    const fetchProjectAcfOptions = async (projectId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/rac_projects/${projectId}`);
        const data = await response.json();
        const projectClientName       = data.acf.client['name'];
        const projectClientLogoUrl    = await fetchImageData(data.acf.client['logo']);
        const projectImagesUrls       = await fetchImageUrls(data.acf.project_images);
        const projectBeforeImagesUrls = await fetchImageUrls(data.acf.before_images);
        const projectAfterImagesUrls  = await fetchImageUrls(data.acf.after_images);
        const projectLaunchDate       = data.acf.launch_date;
        const projectCurrentLink      = data.acf.current_link;
        const projectArchiveLink      = data.acf.archive_link;
        const projectCodeLink         = data.acf.code_link;

        let formattedDate = projectLaunchDate;
        let year          = formattedDate.substring(0,4);
        let month         = formattedDate.substring(4,6);
        let day           = formattedDate.substring(6,8);

        let date                = new Date(year, month-1, day);
        let formattedLaunchDate = dayjs(date).format("MMMM YYYY");

        let projectLink = '';
        let projectLinkDisclaimer = false;
        if ( projectArchiveLink ) {
          projectLink = projectArchiveLink;
          projectLinkDisclaimer = 'The link below is an archived link to reflect the state of the website when it was initially launched.';
        } else if ( projectCurrentLink ) {
          projectLink = projectCurrentLink;
        } else {
          projectLink = false;
        }


        let projectAcfData = {
          client_name             : projectClientName,
          client_logo             : projectClientLogoUrl,
          project_images          : projectImagesUrls,
          before_images           : projectBeforeImagesUrls,
          after_images            : projectAfterImagesUrls,
          launch_date             : formattedLaunchDate,
          project_link            : projectLink,
          project_link_disclaimer : projectLinkDisclaimer,
          code_link               : projectCodeLink
        }
        
        return projectAcfData;

      } catch (error) {
        console.error('Error fetching project ACF options:', error);
      }
    };

    const fetchTechnologies = async (technologies) => {
      try {
        if (technologies.length) {
          const techTerms = await Promise.all(
            technologies.map(async (termId) => {
              const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/rac_technologies/${termId}`);
              const data = await response.json();
              let term = {
                technology : data.name,
                slug : data.slug
              }
              return term;
            })
          );
          return techTerms;
        }
      } catch (error) {
        console.error('Error fetching project ACF options:', error);
      }
    };


    // Fetch the image data using the image ID
    const fetchImageData = async (imageId, fieldName) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}wp/v2/media/${imageId}`);
        const data = await response.json();
        if ( fieldName == 'logo' ) {
          setHeaderImageUrl(data.source_url);
        }
        else if ( fieldName == 'portrait' ) {
          setPortraitUrl(data.source_url);
        }
        else if ( fieldName == 'background_image' ) {
          setBgImageUrl(data.source_url);
        }
        else {
          return data.source_url;
        }
      } catch (error) {
        console.error('Error fetching image data:', error);
      }
    };
    fetchProjects();
    fetchAcfOptions();
  }, []);

  useEffect(() => {

    Fancybox.bind('[data-fancybox]', {
      on : {
        done: (fancybox) => {
          const currentGallery = $(fancybox.getSlide().el);
          if ( currentGallery.find('.projects').length == 0 ) {
            currentGallery.find('.project-images').slick({
              dots: true,
              infinite: true,
              arrows: false,
              slidesToShow: 1,
              adaptiveHeight: true,
            });
          }
        }
      }
    });
    
  }, [projects]);

  return (
    <div className="App">
      {bgImageUrl && <img class="bg-image" src={bgImageUrl} alt="" />}
      <canvas id="codeCanvas"></canvas>
      <header className="App-header">
        <div class="container">
          {headerImageUrl && <img src={headerImageUrl} alt="Header Logo" />}
        </div>
      </header>
  
      <section className="about_bio-skills">
        <div class="container">
          {portraitUrl && <img src={portraitUrl} className="portrait" alt="Robert Curry, Web Developer portrait." />}
          <h1>{greeting}</h1>
          <div class="about-bio">
            {aboutBio && <div dangerouslySetInnerHTML={{__html: aboutBio}}></div>}
            <nav>
              <ul>
              {menuLinks.map((menuLink, index) => (
                <li key={index}><a href={menuLink.link['url']} target={menuLink.link['target']} data-fancybox="navigation">{menuLink.link['title']}</a></li>
              ))}
              </ul>
            </nav>
          </div>
        </div>
       
      </section>
      <section id="about" class="fancybox">
        {aboutSkills && <div dangerouslySetInnerHTML={{__html: aboutSkills}}></div>}
        <div className="logos">
            {skillsLogos.map((url, index) => (
              <div key={index} className="single-logo">
                <img src={url} alt=""/>
              </div>  
            ))}
        </div>
      </section>
      <section id="portfolio" class="fancybox">
        <div class="container projects">
          <div className="title-container">
            {portfolioTitle && <h2>{portfolioTitle}</h2>}
            {portfolioBlurb && <p className="blurb"> {portfolioBlurb}</p>}
          </div>
          
            {projects.map((project, index) => (
              <>
                <a key={index} className="single-project" href={`#${project.slug}`} data-fancybox="projects">
                  {project.imageUrl && <img src={project.imageUrl} alt={project.title.rendered} />}
                  <h3>{project.title.rendered}</h3>
                </a>
                <div id={project.slug} className="fancybox project">
                  <div class="container">
                    <h3>{project.title.rendered}</h3>
                    {project.acf.project_link_disclaimer && <span class="disclaimer">{project.acf.project_link_disclaimer}</span>}
                    <div class="project-details">
                      <span class="launch-date"><i class="fa-solid fa-rocket-launch"></i>{project.acf.launch_date}</span>
                      {project.acf.project_link && <a href={project.acf.project_link} target="_blank"><i class="fa-solid fa-globe-pointer"></i> Visit the Website</a>}
                      {project.acf.code_link && <a href={project.acf.code_link} target="_blank"><i class="fa-solid fa-code"></i> View the Code</a>}
                      <div class="technologies">
                        {project.rac_technologies.length && project.rac_technologies.map((term, index) => (
                          <span key={index} data-slug={term.slug}>{term.technology}</span>
                        ))}
                      </div>
                    </div>
                    <div class="project-images">
                      
                      {project.acf.project_images && project.acf.project_images.map((url, index) => (
                        <div key={index} className="single-project-image">
                          <img src={url} alt={project.title}/>
                        </div>  

                      ))}
                    </div>
                    <div className="project-description">
                      {project.acf.client_logo && <img className="client-logo" src={project.acf.client_logo} />}
                      <div dangerouslySetInnerHTML={{ __html: project.content.rendered }}/>
                    </div>
                    <div className="before-afters">
                        <div className="before">
                          <label>Before</label>
                          {project.acf.before_images && project.acf.before_images.map((url, index) => (
                            <div key={index} className="single-project-before-image">
                              <img src={url} />
                            </div>  
                          ))}
                        </div>
                        <div className="after">
                          <label>After</label>
                          {project.acf.after_images && project.acf.after_images.map((url, index) => (
                            <div key={index} className="single-project-after-image">
                              <img src={url} />
                            </div>  
                          ))}
                        </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        
      </section>
      <section id="contact" class="fancybox">
        <div class="container">
          <div>
            <h2>Let's Connect!</h2>
            {contactMe && <div dangerouslySetInnerHTML={{__html: contactMe}}></div>}
            {linkedInUrl && <a href={linkedInUrl} target="_blank"><i class="fa-brands fa-linkedin"></i> Message me on LinkedIn!</a>}         
          </div>
          <HubspotForm
                portalId={contactMePortalId}
                formId={contactMeFormId}
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
        </div>
      
      </section>
    </div>
  );
}

export default App;